.main {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.logoCont {
  width: 100%;
  background-color: white;
  padding: 50px;
}

.logo {
  height: auto;
  max-height: 30px; /* Maintained consistent max-height */
  width: auto;
  max-width: 100%;
}

.tick_icon {
  height: auto;
  max-height: 40px; /* Maintained consistent max-height */
  width: auto;
  max-width: 100%;
  animation: scaleIn 0.5s ease-in-out;
}

.content {
  flex: 1;
  min-height: 70%;
  width: 100%; /* Consistent with mobile view */
  background-color: #16462e;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
}

.welcome {
  color: white;
  font-family: 'AktivGroteskRegular';
  font-size: 3vw;
  margin: 0;
  animation: slideIn 1s ease-in-out;
  animation-delay: 0.5s;
  animation-fill-mode: backwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.congratulations {
  font-size: 1.5em; /* Adjust the size as needed */
  font-weight: bold;
  margin-top: 10px; /* Adjust the spacing as needed */
  color: #ffffff; /* Adjust the color as needed */
  animation: fadeIn 2.5s ease-in-out;
}

.text {
  color: white;
  font-weight: bold;
  margin: 0;
  text-align: center;
  margin-top: 20px;
  animation: fadeIn 1.5s ease-in-out;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}

.button {
  background-color: white;
  color: #16462e;
  padding: 10px 20px;
  border: none; /* Remove default border */
  outline: none; /* Remove default outline */
  border-radius: 8px;
  font-weight: bold;
  margin-top: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.button:active {
  outline: none;
  border: none;
}

.button:hover {
  background-color: #16462e;
  color: white;
}

/* Media Queries for responsiveness */
@media (max-width: 480px) {
  .logoCont {
    display: flex;
    justify-content: center;
    padding: 30px;
  }

  .content {
    flex: 1;
    width: 100%; /* Full width on smaller screens */
    padding: 30px;
  }

  .welcome {
    font-size: 8vw; /* Dynamic sizing for mobile devices */
  }

  .tick_icon {
    height: auto;
    max-height: 30px; /* Maintained consistent max-height */
    width: auto;
    max-width: 100%;
  }
}
