@font-face {
  font-family: 'AktivGrotesk';
  src: url('./assets/fonts/AktivGrotesk-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./assets/fonts/AktivGrotesk-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'AktivGrotesk';
  src: url('./assets/fonts/AktivGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'AktivGroteskRegular';
  src: url('./assets/fonts/AktivGrotesk-BoldItalic.ttf') format('truetype');
  font-weight: bold;
}

body {
  margin: 0;
  font-family: 'AktivGrotesk', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
